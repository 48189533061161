<template>
    <!-- 按钮 -->
    <div class="d-flex justify-content-between mb-3">
        <button v-permit="'user.add'" type="button" class="btn btn-success me-3" @click="addClick()">
            <i class="ri-add-fill me-1 align-bottom"></i>
            {{ $t('user.add') }}
        </button>
    </div>

    <!-- 关键字搜索 -->
    <b-row>
        <b-col>
            <b-row>
                <b-col :cols="cols">
                    <div style="position: relative; top: 100%; transform: translateY(-100%);">
                        <div class="search-box">
                            <input type="text" class="form-control" :placeholder="$t('user.keyword_placeholder')"
                                v-model="form.keyword" />
                            <i class="ri-search-line search-icon"></i>
                        </div>
                    </div>
                </b-col>
                <b-col :cols="cols">
                    <label for="weight-field" class="form-label">{{ $t('attribute.create_time') }}</label>
                    <flat-pickr class="form-control flatpickr-input" v-model="createDate" :config="dateConfig">
                    </flat-pickr>
                </b-col>
                <b-col :cols="cols">
                    <label for="weight-field" class="form-label">{{ $t('attribute.role') }}</label>
                    <Multiselect v-model="form.role" mode="tags" :close-on-select="true" :searchable="false"
                        :create-option="false" :options="roles" />
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="auto">
            <div class="row" style="position: relative; top: 100%; transform: translateY(-100%);">
                <div>
                    <div class="col-auto">
                        <button type="button" class="btn btn-primary me-3" @click="searchClick()">
                            <i class="ri-search-line me-1 align-bottom"></i>
                            {{ $t('base.search') }}
                        </button>
                        <button type="button" class="btn btn-info" @click="resetClick()">
                            <i class="ri-reply-line me-1 align-bottom"></i>
                            {{ $t('base.reset') }}
                        </button>
                    </div>
                </div>
            </div>
        </b-col>
    </b-row>
</template>

<script>
    import {
        getRoleSelect
    } from '@/service/index';
    import _ from 'loadsh';
    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';
    import {
        Mandarin
    } from 'flatpickr/dist/l10n/zh.js'; //引入普通话语言包
    import {
        english
    } from 'flatpickr/dist/l10n/default.js';
    import {
        isChinese
    } from '@/i18n';

    export default {
        components: {
            flatPickr
        },
        emits: ['add', 'update:searchInfo'],
        props: {
            searchInfo: {
                type: Object,
                default: () => {}
            },
        },
        data() {
            return {
                cols: 4,
                roles: [],
                form: {
                    keyword: '',
                    role: []
                },
                dateConfig: {
                    wrap: true,
                    altFormat: 'Y-m-d',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    mode: 'range',
                    locale: Mandarin
                },
                createDate: null
            };
        },
        created() {
            if (!isChinese()) {
                this.dateConfig = {
                    wrap: true,
                    altFormat: 'Y-m-d',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    mode: 'range',
                    locale: english
                };
            }
            this.requestRoles();
        },
        methods: {
            addClick() {
                this.$emit('add');
            },
            searchClick() {
                const params = {
                    keyword: this.form.keyword,
                    start_date: this.form.start_date,
                    end_date: this.form.end_date
                };
                // params['role'] = _.join(this.form.role, ',');
                if (this.form.role.length) {
                    const tmp = JSON.stringify(this.form.role);
                    params['role'] = tmp;
                }
                this.$emit('update:searchInfo', params);
            },
            resetClick() {
                this.form = {
                    keyword: '',
                    role: []
                };
                this.createDate = null;
                this.$emit('update:searchInfo', {});
            },
            requestRoles() {
                getRoleSelect()
                    .then(res => {
                        if (res && res.payload) {
                            this.roles = _.map(res.payload, 'name');
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        },
        watch: {
            createDate(val) {
                if (val && (val.includes('to') || val.includes('至'))) {
                    let time = '';
                    if (val.includes('to')) {
                        time = val.split('to');
                    } else {
                        time = val.split('至');
                    }
                    let start_date = time[0];
                    let end_date = time[1];
                    start_date = _.trim(start_date, ' ');
                    end_date = _.trim(end_date, ' ');
                    this.form.start_date = start_date;
                    this.form.end_date = end_date;
                } else {
                    let start_date = val;
                    let end_date = val;
                    start_date = _.trim(start_date, ' ');
                    end_date = _.trim(end_date, ' ');
                    this.form.start_date = start_date;
                    this.form.end_date = end_date;
                }
            }
        }
    };
</script>

<style></style>
