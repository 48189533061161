<template>
  <div class="modal fade show" style="display: block">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-light p-3">
          <h5 class="modal-title">{{ formTitle }}</h5>
          <button
            type="button"
            class="btn-close"
            @click="handleClose()"
          ></button>
        </div>
        <form action="#" id="addform">
          <div class="modal-body">
            <div class="row gy-3">
              <div>
                <label for="weight-field" class="form-label">
                  <span class="text-danger">*</span>
                  <span>{{ $t("attribute.name") }}</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.name"
                  :class="{
                    'is-invalid': submitted && v$.form.name.$error,
                  }"
                  required
                />
                <div
                  v-for="(item, index) in v$.form.name.$errors"
                  :key="index"
                  class="invalid-feedback"
                >
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>
              <div>
                <label for="weight-field" class="form-label">
                  <span class="text-danger">*</span>
                  <span>{{ $t("attribute.user_name") }}</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.username"
                  :class="{
                    'is-invalid': submitted && v$.form.username.$error,
                  }"
                  required
                />
                <div
                  v-for="(item, index) in v$.form.username.$errors"
                  :key="index"
                  class="invalid-feedback"
                >
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>
              <div>
                <label for="weight-field" class="form-label">
                  <span class="text-danger">*</span>
                  <span>{{ $t("attribute.password") }}</span>
                </label>
                <div class="position-relative auth-pass-inputgroup">
                  <input
                    :type="passwordType"
                    autocomplete="new-passWord"
                    class="form-control"
                    style="padding-right: 3rem"
                    v-model="form.password"
                    :class="{
                      'is-invalid': submitted && v$.form.password.$error,
                    }"
                    required
                    @input="passwordInput"
                  />
                  <div
                    v-for="(item, index) in v$.form.password.$errors"
                    :key="index"
                    class="invalid-feedback"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                  <button
                    class="
                      btn btn-link
                      position-absolute
                      top-0
                      text-decoration-none text-muted
                    "
                    style="right: 3%"
                    type="button"
                    id="password-addon"
                  >
                    <i
                      class="ri-eye-fill align-middle"
                      @click="viewPasswordClick()"
                    ></i>
                  </button>
                </div>
              </div>
              <div>
                <label for="weight-field" class="form-label">
                  <span class="text-danger">*</span>
                  <span>{{ $t("attribute.role") }}</span>
                </label>
                <Multiselect
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && v$.form.role.$error,
                  }"
                  v-model="form.role"
                  :options="roles"
                  required
                />
                <div
                  v-for="(item, index) in v$.form.role.$errors"
                  :key="index"
                  class="invalid-feedback"
                >
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>
              <div>
                <label class="form-label">{{
                  $t("attribute.organization")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.department"
                />
              </div>
              <div>
                <label for="weight-field" class="form-label">{{
                  $t("attribute.description")
                }}</label>
                <b-form-textarea
                  id="textarea"
                  v-model="form.description"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </div>
              <div>
                <label class="form-label">{{ $t("attribute.phone") }}</label>
                <input type="text" class="form-control" v-model="form.phone" />
              </div>
              <div>
                <label class="form-label">{{ $t("attribute.email") }}</label>
                <input type="text" class="form-control" v-model="form.mail" />
              </div>
              <!-- <div class="d-flex">
								<span class="text-danger">*</span>
								<label for="weight-field" class="form-label">{{ $t('attribute.status') }}</label>
								<b-form-checkbox
									v-model="form.disabled"
									switch
									size="lg"
									style="margin-left: -30px; position: relative; transform: translateY(-70%);"
								></b-form-checkbox>
							</div> -->
              <div>
                <span class="text-danger">*</span>
                <label for="weight-field" class="form-label">{{
                  $t("attribute.status")
                }}</label>
                <b-form-checkbox
                  v-model="form.disabled"
                  switch
                ></b-form-checkbox>
              </div>
              <template v-for="(item, key) in grantAll" :key="key">
                <div v-if="item.show">
                  <b-form-checkbox v-model="item.value">{{
                    item.title
                  }}</b-form-checkbox>
                </div>
              </template>
            </div>
          </div>
          <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
              <button
                type="button"
                class="btn btn-light"
                @click="handleClose()"
                :disabled="formValidataAlert"
              >
                {{ $t("base.cancel") }}
              </button>
              <button
                type="button"
                class="btn btn-success"
                id="add-btn"
                @click="handleSubmit()"
                :disabled="formValidataAlert"
              >
                {{ $t("base.done") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
  <message-alert
    :alertInfo="alertInfo"
    v-if="formValidataAlert"
    :showCloseBtn="true"
    v-model:formValidataAlert="formValidataAlert"
  ></message-alert>
</template>
<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import _ from "loadsh";
import i18n from "@/i18n";
import { isAdmin, getLoginData } from "@/utils/permission.js";
import {
  getRoleSelect,
  createUser,
  editUser,
  userDetail,
} from "@/service/index";

export default {
  emits: ["update:isShowForm", "initRoleList"],
  props: {
    isShowForm: Boolean,
    formType: {
      type: String,
      default: "add",
    },
    singleData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    form: {
      name: {
        required: helpers.withMessage(
          i18n.global.t("user.hint_name"),
          required
        ),
      },
      username: {
        required: helpers.withMessage(
          i18n.global.t("user.hint_user_name"),
          required
        ),
      },
      password: {
        required: helpers.withMessage(
          i18n.global.t("user.hint_password"),
          required
        ),
      },
      role: {
        required: helpers.withMessage(
          i18n.global.t("user.hint_role"),
          required
        ),
      },
    },
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        disabled: false,
      },
      submitted: false,
      formValidataAlert: false,
      alertInfo: {
        variant: "success",
        content: "",
        icon: "ri-error-warning-line",
      },
      roles: [],
      passwordType: "password",
      grantAll: {
        grant_all_garment: {
          title: this.$t("base.grant_all_garment"),
          show: false,
          value: false,
        },
        grant_all_fabric: {
          title: this.$t("base.grant_all_fabric"),
          show: false,
          value: false,
        },
        grant_all_yarn: {
          title: this.$t("base.grant_all_yarn"),
          show: false,
          value: false,
        },
        grant_all_fibre: {
          title: this.$t("base.grant_all_fibre"),
          show: false,
          value: false,
        },
        grant_all_hometextile: {
          title: this.$t("base.grant_all_hometextile"),
          show: false,
          value: false,
        },
        grant_all_textile: {
          title: this.$t("base.grant_all_textile"),
          show: false,
          value: false,
        },
      },
      hasEdit: false,
    };
  },
  created() {
    this.requestRoles();
    this.initGrantAllData();
  },
  computed: {
    formTitle() {
      if (this.formType === "edit") {
        return this.$t("user.edit");
      } else {
        return this.$t("user.add");
      }
    },
  },
  methods: {
    passwordInput() {
      if (!this.hasEdit) {
        this.form.password = "";
      }
      this.hasEdit = true;
    },
    initGrantAllData() {
      _.forEach(this.grantAll, function (value, key) {
        if (isAdmin()) {
          value.show = true;
        } else {
          const show = getLoginData()[key];
          value.show = show;
        }
      });
    },
    showGrantAll(name) {
      if (isAdmin()) {
        return true;
      }
      const result = getLoginData()[name];
      return result;
    },
    viewPasswordClick() {
      if (this.formType == "edit") {
        if (!this.hasEdit) {
          return;
        }
      }
      if (this.passwordType == "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    handleClose() {
      this.$emit("update:isShowForm", false);
    },
    handleSubmit() {
      this.submitted = true;
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      } else {
        let params = _.cloneDeep(this.form);
        params["confirm_password"] = this.form.password;
        params["disabled"] = !this.form.disabled;
        if (this.form.role) {
          params["role"] = [this.form.role];
        } else {
          params["role"] = [];
        }

        _.forEach(this.grantAll, function (value, key) {
          if (value.show) {
            params[key] = value.value;
          }
        });

        if (this.formType == "edit") {
          params["id"] = this.form.id;
          if (!this.hasEdit) {
            delete params.password;
            delete params.confirm_password;
          }
          editUser(params)
            .then((res) => {
              if (res) {
                let info = {
                  variant: "success",
                  content: this.$t("base.edit_success"),
                  icon: "ri-error-warning-line",
                };
                this.openAlert(info, "close");
                this.$emit("initRoleList");
              }
            })
            .catch((err) => {
              this.errorAlert(err.message);
            });
        } else {
          createUser(params)
            .then((res) => {
              if (res) {
                if (res.code == 0) {
                  let info = {
                    variant: "success",
                    content: this.$t("base.add_success"),
                    icon: "ri-error-warning-line",
                  };
                  this.openAlert(info, "close");
                  this.$emit("initRoleList");
                } else {
                  this.errorAlert(res.message);
                }
              }
            })
            .catch((err) => {
              this.errorAlert(err.message);
            });
        }
      }
    },
    /* 消息提示框 */
    openAlert(info, close) {
      if (info) {
        this.alertInfo = info;
      }
      this.formValidataAlert = true;
      if (close) {
        setTimeout(() => {
          this.formValidataAlert = false;
          this.handleClose();
        }, 2000);
      }
    },
    errorAlert(message) {
      let info = {
        variant: "danger",
        content: message,
        icon: "ri-error-warning-line",
      };
      this.alertInfo = info;
      this.formValidataAlert = true;
      if (close) {
        setTimeout(() => {
          this.formValidataAlert = false;
        }, 2000);
      }
    },
    requestRoles() {
      getRoleSelect()
        .then((res) => {
          if (res && res.payload) {
            let roleData = res.payload;
            roleData = this.filterRoles(roleData);
            this.roles = _.map(roleData, "name");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /* 筛选角色列表 */
    filterRoles(roles) {
      let newData = [];
      roles.forEach((role) => {
        if (role.childs && role.childs.length) {
          newData.push(role);
          // 存在多层嵌套
          let reData = this.filterRoles(role.childs);
          newData.push(...reData);
        } else {
          newData.push(role);
        }
      });
      return newData;
    },
    requestDetail() {
      userDetail(this.form.id)
        .then((res) => {
          if (res && res.payload) {
            this.form = _.cloneDeep(res.payload);
            this.form.disabled = !this.form.disabled;
            if (this.form.role && this.form.role.length) {
              this.form.role = this.form.role[0];
            }
            const self = this;
            _.forEach(this.grantAll, function (value, key) {
              value.value = self.form[key];
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    singleData: {
      handler(val) {
        if (val) {
          this.form = _.cloneDeep(val);
          if (val.id) {
            this.requestDetail();
          } else {
            this.form.disabled = !this.form.disabled;
            if (this.form.role && this.form.role.length) {
              this.form.role = this.form.role[0];
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
    formType: {
      handler(val) {
        console.log(val);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
