<template>
	<div class="form p-3" v-if="tableInfo.length">
		<!-- Bordered Tables -->
		<table class="table table-bordered table-layout-fixed">
			<thead class="table-light">
				<tr>
					<th scope="col" v-for="(head, index) in table_head" :key="index">{{ head }}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(info, index) in tableInfo" :key="index" style="vertical-align: middle">
					<td>{{ info.name }}</td>
					<td>{{ info.username }}</td>
					<td>{{ info.mail }}</td>
					<td>{{ info.phone }}</td>
					<td>{{ info.department }}</td>
					<td>{{ role(info) }}</td>
					<td style="word-wrap：break-word">
						{{info.description}}
					</td>
					<!-- <td class="time">{{ (info.update_time && moment(info.update_time).format('YYYY-MM-DD HH:mm')) || '-' }}</td> -->
                    <td class="time">{{ dateFormat1(info.update_time) || '-' }}</td>
					<td>
						<b-form-checkbox v-model="info.enabled" switch @change="switchChange(info)"></b-form-checkbox>
					</td>
					<td v-if="showOperation()">
						<ul class="list-inline hstack gap-2 mb-0">
							<!-- 编辑 -->
							<li v-permit="'user.modify'" class="list-inline-item cursor">
								<div class="text-primary d-inline-block" @click="editClick(info)"><i class="fs-16 ri-pencil-fill"></i></div>
							</li>
							<!-- 删除 -->
							<li v-permit="'user.delete'" class="list-inline-item" title="delete">
								<div class="d-inline-block text-danger cursor" @click="deleteClick(info)"><i class="fs-16 ri-delete-bin-5-fill"></i></div>
							</li>
						</ul>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<message-alert :alertInfo="alertInfo" v-if="formValidataAlert"></message-alert>
</template>

<script>
import moment from 'moment';
import _ from 'loadsh';
import { dateFormat1 } from '@/utils/common.js';

export default {
	emits: ['operation'],
	props: {
		tableInfo: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			table_head: [
				this.$t('attribute.name'),
				this.$t('attribute.user_name'),
				this.$t('attribute.email'),
				this.$t('attribute.phone'),
				this.$t('attribute.organization'),
				this.$t('attribute.role'),
				this.$t('attribute.description'),
				this.$t('attribute.last_time'),
				this.$t('attribute.status'),
				this.$t('base.operation')],
			alertInfo: {
				variant: 'danger',
				content: '',
				icon: 'ri-error-warning-line'
			},
			formValidataAlert: false,
            dateFormat1,
		};
	},
	created() {
		if (!this.showOperation()) {
			this.table_head.pop()
		}
	},
	computed: {
		
	},
	methods: {
		moment,
		switchChange(info) {
			this.$emit('operation', 'updateStatus', info)
		},
		editClick(info) {
			this.$emit("operation",  'edit', info)
		},
		deleteClick(info) {
			this.$emit("operation", 'delete', info)
		},
		role(item) {
			const role = item.role
			return _.join(role, ',')
		},
		showOperation() {
			const params = {
				list: ['user.modify', 'user.delete'],
				type: 'some'
			};
			return this.$permit(params)
		}
	},
};
</script>

<style scoped lang="scss"></style>
