<template>
    <Layout>
        <PageHeader :title="PageHeaderT" :items="PageHeaderItems" />
        <div class="block_content d-flex flex-column">
            <div class="card p-4">
                <Search v-model:searchInfo="params" @add="addClick"></Search>
            </div>
            <div class="card mb-0 flex-1 overflow-hidden">
                <div class="flex-1 overflow-auto">
                    <Form :tableInfo="tableData" @operation="operationClick"></Form>
                </div>
                <Pagination v-if="pagination.total" v-model:page="pagination.currentPage"
                    v-model:perPage="pagination.pageSize" :pages="pagination.pageOption"
                    :prePageOption="pagination.pageSizeOption" :tab_info_length="pagination.total" class="me-5 my-3">
                </Pagination>
            </div>
        </div>
        <Add v-if="isShow" :singleData="singleData" :formType="formType" v-model:isShowForm="isShow"
            @initRoleList="refreshList"></Add>
        <message-alert :alertInfo="alertInfo" v-if="formValidataAlert"></message-alert>
    </Layout>
</template>

<script>
    import Search from './Search';
    import Form from './Form';
    import Add from './Add';
    import {
        getUser,
        deleteUser,
        editUser
    } from '@/service';
    import _ from 'loadsh';
    import Swal from 'sweetalert2';
    import {
        tableMixin
    } from '@/mixins/Table1.js'
    export default {
        components: {
            Search,
            Form,
            Add
        },
        mixins: [tableMixin],
        data() {
            return {
                PageHeaderT: this.$t('user.title'),
                PageHeaderItems: [{
                    text: this.$t('user.title'),
                    active: true,
                    href: '/user'
                }],
                isShow: false,
                formType: 'add',
                singleData: null,
                formValidataAlert: false,
            };
        },
        methods: {
            operationClick(type, data) {
                if (type == 'edit') {
                    this.editData(data);
                } else if (type == 'delete') {
                    this.deleteData(data);
                } else if (type == 'updateStatus') {
                    this.updateStatus(data);
                }
            },
            addClick() {
                this.formType = 'add';
                this.singleData = {
                    name: '',
                    username: '',
                    password: '',
                    role: '',
                    department: '',
                    phone: '',
                    mail: '',
                    description: '',
                    disabled: true
                };
                this.isShow = true;
            },
            updateStatus(info) {
                let params = _.cloneDeep(info);
                params.disabled = !params.disabled
                delete params.password
                editUser(params)
                    .then(res => {
                        if (res) {
                            this.requestList(this.params);
                        }
                    })
                    .catch(err => {
                        this.errorAlert(err.message);
                        this.requestList(this.params);
                    });
            },
            editData(info) {
                this.formType = 'edit';
                this.singleData = info;
                this.isShow = true;
            },
            deleteData(info) {
                Swal.fire({
                    title: this.$t('base.delete_alert_title'),
                    text: this.$t('base.delete_alert_info'),
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonColor: '#f46a6a',
                    cancelButtonText: this.$t('base.cancel'),
                    confirmButtonColor: '#34c38f',
                    confirmButtonText: this.$t('base.done')
                }).then(result => {
                    if (result.isConfirmed) {
                        deleteUser(info.id)
                            .then(res => {
                                if (res) {
                                    let info = {
                                        variant: 'success',
                                        content: this.$t('base.delete_alert_completed'),
                                        icon: 'ri-error-warning-line'
                                    };
                                    this.openAlert(info, 'close');
                                }
                            })
                            .then(() => {
                                this.refreshList1();
                            })
                            .catch(e => {
                                let info = {
                                    variant: 'danger',
                                    content: e.message,
                                    icon: 'ri-error-warning-line'
                                };
                                this.openAlert(info);
                            });
                    }
                });
            },
            /* 消息提示框 */
            openAlert(info, close) {
                if (info) {
                    this.alertInfo = info;
                }
                this.formValidataAlert = true;
                if (close) {
                    setTimeout(() => {
                        this.formValidataAlert = false;
                    }, 2000);
                }
            },
            requestList(params) {
                getUser(params)
                    .then(res => {
                        if (res && res.payload && res.payload.data) {
                            let data = _.cloneDeep(res.payload.data);
                            data.forEach(function(item) {
                                item.enabled = !item.disabled
                            });
                            const payload = {
                                data: data,
                                total: res.payload.total
                            }
                            this.updateData(payload)
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        },
    };
</script>

<style lang="scss">
    .table-responsive {
        min-height: 60vh;
        max-height: 60vh;
    }

    .mask_model {
        width: 100vw;
        height: 100vh;
        background-color: rgba(47, 79, 79, 0.6);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    }
</style>
